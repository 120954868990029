import * as React from "react"
import {Link} from "gatsby"
import {Parallax} from "@react-spring/parallax"
import {Themed} from "theme-ui"
import Layout from "../components/layout";
import Divider from "../elements/divider";
import {UpDown, UpDownWide} from "../styles/animations";
import Svg from "../components/svg";
import Seo from "../components/seo";
import Content from "../elements/content";
import Inner from "../elements/inner";

const NotFound = () => (
    <Layout>
        <Seo title="404 - Not Found"/>
        <Parallax pages={1}>
            <div>
                <Divider speed={0.2} offset={0} factor={1}>
                    <UpDown>
                        <Svg icon="1" width={48} left="10%" top="20%"/>
                        <Svg icon="2" width={48} left="60%" top="70%"/>
                        <Svg icon="3" width={6} left="60%" top="15%"/>
                    </UpDown>
                    <UpDownWide>
                        <Svg icon="4" width={16} left="80%" top="10%"/>
                        <Svg icon="5" width={12} left="90%" top="50%"/>
                        <Svg icon="6" width={16} color="icon_darker" left="70%" top="90%"/>
                        <Svg icon="7" width={16} left="30%" top="65%"/>
                        <Svg icon="8" width={16} left="28%" top="15%"/>
                        <Svg icon="9" width={6} left="75%" top="10%"/>
                        <Svg icon="10" width={8} left="45%" top="10%"/>
                    </UpDownWide>
                    <Svg icon="11" width={24} color="icon_darker" left="5%" top="70%"/>
                    <Svg icon="12" width={6} left="4%" top="20%"/>
                    <Svg icon="13" width={12} left="50%" top="60%"/>
                    <Svg icon="1" width={8} left="95%" top="90%"/>
                    <Svg icon="2" width={24} left="40%" top="80%"/>
                    <Svg icon="3" width={8} left="25%" top="5%"/>
                    <Svg icon="2" width={64} left="95%" top="5%"/>
                    <Svg icon="1" width={64} color="icon_purple" left="5%" top="90%"/>
                    <Svg icon="3" width={6} left="10%" top="10%"/>
                    <Svg icon="1" width={12} left="40%" top="30%"/>
                    <Svg icon="1" width={16} left="10%" top="50%"/>
                    <Svg icon="1" width={8} left="80%" top="70%"/>
                </Divider>
                <Content sx={{variant: `texts.bigger`}} speed={0.4} offset={0} factor={1}>
                    <Inner>
                        <Themed.h1>404 - Page not found</Themed.h1>
                        <Themed.p>
                            Go back to <Link to="/">homepage</Link>.
                        </Themed.p>
                    </Inner>
                </Content>
            </div>
        </Parallax>
    </Layout>
)

export default NotFound
